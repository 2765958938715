import { Maybe, PlainObject } from 'shared/util/types.util'
import { GuidedAdvice } from 'interfaces/GuidedFlow'
import { HELP_CENTER_URL_BASE } from 'components_contributor/constants'
import {
  authenticateUserContributorOnboardingPath,
  contributorApiOnboardingInviteUserPath,
  contributorApiOnboardingUpdateInvitedPartnerPath,
  contributorApiOnboardingUserOnboardedPath,
  guidedSiteTinyPath,
  identifyUserContributorOnboardingPath,
} from 'generated/routes'
import { GuidedAdviceDetailed } from 'components_contributor/interfaces/commonTypes'

interface ObjectWithToken {
  accessToken: string
}

const siteSlugRegex = /[^a-zA-Z0-9-]/g

export const companyAtSiteSlug = (guidedAdvice: GuidedAdviceDetailed): string => {
  const { site, invitedCompany } = guidedAdvice
  return [site.name, invitedCompany.name].join('-')
    .replace(/\s/g, '-')
    .replace(siteSlugRegex, '-')
}

export const invitationAccessToken = (guidedAdvice: GuidedAdviceDetailed): string => guidedAdvice.accessToken

export const newBookingUrl = (guidedAdvice: GuidedAdviceDetailed, params = {}): string => (
  guidedSiteTinyPath(
    companyAtSiteSlug(guidedAdvice),
    guidedAdvice.siteId,
    guidedAdvice.id,
    { t: invitationAccessToken(guidedAdvice), ...params },
  ))

export const getLocale = (locale: string): string => (locale === 'de' ? 'de' : 'en')

export const helpCenterUrlWithLocale = (locale = 'de'): string => {
  const localeCode = getLocale(locale)
  return `${HELP_CENTER_URL_BASE}/${localeCode}/`
}

export const tlTutorialUrlWithLocale = (locale = 'de'): string => {
  const helpCenterUrlBaseWithLocale = helpCenterUrlWithLocale(locale)
  return `${helpCenterUrlBaseWithLocale}collections/11735-tutorials`
}

export const tlFAQUrlWithLocale = (locale = 'de'): string => {
  const helpCenterUrlBaseWithLocale = helpCenterUrlWithLocale(locale)
  const booking = locale === 'de' ? 'buchung' : 'booking'
  return `${helpCenterUrlBaseWithLocale}collections/4932-${booking}`
}

export const contributorEntrypointPath = (
  guidedAdvice: GuidedAdvice, params: Maybe<PlainObject> = {},
): string => {
  const { accessToken } = guidedAdvice
  return authenticateUserContributorOnboardingPath({ t: accessToken, ...params })
}

export const contributorOnboardingPath = (guidedAdvice: GuidedAdvice | GuidedAdviceDetailed,
  params: Maybe<PlainObject>): string => {
  const { accessToken } = guidedAdvice
  return identifyUserContributorOnboardingPath({ t: accessToken, ...params })
}

export const userOnboardedContributorPath = (
  { accessToken }: ObjectWithToken,
): string => contributorApiOnboardingUserOnboardedPath({ t: accessToken })

export const inviteUserPath = (
  { accessToken }: ObjectWithToken,
): string => contributorApiOnboardingInviteUserPath({ t: accessToken })

export const updateInvitedUserPath = (
  { accessToken }: ObjectWithToken,
): string => contributorApiOnboardingUpdateInvitedPartnerPath({ t: accessToken })
