export enum ADVICE_EDIT_FLOW_TABS {
  calendar = 'calendar',
  loadingUnits = 'loadingUnits',
  documents = 'documents',
  vehicle = 'vehicle',
  referenceNumber = 'referenceNumber',
}

export enum MULTI_SHIPMENT_ADVICE_EDIT_FLOW_TABS {
  shipments = 'shipments',
  documents = 'documents',
  vehicle = 'vehicle',
  calendar = 'calendar',
}

export const HELP_CENTER_URL_BASE = 'https://intercom-help.eu/tradelink-FAQ'
